// 封装请求方法
import request from '@/api/requestShupets'

// 文章类别
export const knowledgeType = (data) => {
  return request({
    url: '/article/api/knowledge/type/page',
    method: 'post',
    data: data,
  })
}
// 文章
export const knowledge = (data) => {
  return request({
    url: '/article/api/article/knowledge/page',
    method: 'post',
    data: data,
  })
}
// 文章详情
export const knowledgeDetail = (data) => {
  return request({
    url: '/article/api/article/knowledge/detail',
    method: 'post',
    data: data,
  })
}
