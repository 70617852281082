<template>
    <div class="main">
        <div class="title">{{ knowledgeInfo.title }}</div>
        <div class="content" v-html="knowledgeInfo.content"></div>
    </div>
</template>

<script>
  import { knowledgeDetail } from '@/api/shupetsApi'
  export default {
    data() {
      return {
        knowledgeInfo: {}
      }
    },
    async created() {
      await this.knowledgeDetail(this.$route.query.no)
    },
    methods: {
      async knowledgeDetail(_no) {
        await knowledgeDetail({
            articleNo: _no
        }).then(res=>{
          this.knowledgeInfo = res.data
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
    .main {
        width: 100%;
    }

    .title {
        text-align: center;
        margin: 20px;
        font-size: 14px;
        font-weight: 500;
    }

    .content {
        font-size: 13px;
        text-align: center;
        p {
          white-space: pre-wrap !important;
        }
        img {
          width: 100%;
        }
    }

    /deep/ .content img {
      width: 100%;
    }

    /deep/ .content p {
      white-space: pre-wrap !important;
    }

    /deep/ .content span {
      white-space: pre-wrap !important;
    }

</style>